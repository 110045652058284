import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const res = await fetch("https://serverless-api.mihai-tenea01.workers.dev/api/posts");
            const posts = await res.json();
            setPosts(posts);
            console.log(posts);
        };

        getPosts();
    }, []);

    return (
        <div>
            <nav>
                <Link to="/posts/add">Add Post</Link>
            </nav>
            <h1>Posts</h1>
            {/* {posts.map((post: any) => (
                <div>
                    <h2 key={post.title}>{post.title}</h2>
                </div>
            ))} */}
        </div>
    );
};

export default Posts;