import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const PostsAdd = () => {
    let navigate = useNavigate();
    const [inputs, setInputs] = useState({} as any);

    async function postInput() {
        const requestOptions = {
            method: "POST",
            body: JSON.stringify(inputs)
        };

        const res = await fetch("https://serverless-api.mihai-tenea01.workers.dev/api/posts", requestOptions);
        console.log(await res.json());
        // if(res.status === 200)
        //     navigate("/");
    }

    const handleChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs((values: any) => ({...values, [name]: value}))
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        postInput();
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>Enter the post title:
                <input type="text" name="title" value={inputs.title || ""} onChange={handleChange} />
            </label>
            <label>Enter the post content:
                <textarea name="content" value={inputs.content || ""} onChange={handleChange} />
            </label>
            <input type="submit" />
        </form>
    );
};

export default PostsAdd;